body {
  margin: 0;
  height: 100%;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
}

div {
  display: block;
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: black;
}
i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}

.col-6,
.col-4,
.row {
  margin: 0;
  padding: 0;
}
.Announcement {
  background-color: rgba(51, 143, 224, 0.95);
  padding: 10px 0px 100px;
}

.chooseimage {
  margin: 15px;
  border-radius: 10px;
}

.imagesize {
  width: 50%;
  height: 50%;
  justify-content: center;
}

.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}

.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}

.banner-image {
  /* opacity: 1; */
  background-image: url(https://dclm.org/wp-content/uploads/2022/12/great-transformation-web-1680x1050.jpg);
  background-attachment: fixed;
  background-size: cover;
  margin-top: -1;
  padding: 300px 0;
}
/* Header */

.header {
  padding: 1px 0px;
}

.navbar-brand img {
  width: 60%;
}

.input-group {
  height: 50px;
  width: 90%;
}
.search {
  height: 100%;
}

.search-button {
  width: 20%;
  background-color: black;
  color: white;
  border: 0;
  text-transform: uppercase;
  font-size: 12px;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.dropdown-menu a:hover {
  background-color: #1cb803 !important;
  color: #ffffff !important;
}

.mobile-header {
  display: none;
}

/* Shop */

.shop {
  margin-bottom: 50px;
}

.section {
  width: 100%;
  padding-bottom: 6%;
  padding-top: 2%;
}

.sidebar_item {
  margin-bottom: 35px;
}

.sidebar_item h4 {
  color: #1c1c1c;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}

.sidebar_item ul {
  margin: 0;
  padding: 0;
}

.sidebar_item ul li {
  list-style: none;
  display: flex;
}

.sidebar_item ul li a {
  font-size: 16px;
  color: #000000;
  line-height: 45px;
  display: block;
  font-family: "Open Sans", sans-serif;
}
.sidebar_item ul li img {
  width: 25px;
  object-fit: contain;
  margin-left: 20px;
}

.border-product {
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
}

.product-count {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  margin-top: 40px;
}
.shopBack {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #f3fbf7;
}
.shopBack img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.shoptext {
  padding-top: 10px;
}
.shoptext p a {
  color: #252525;
}

.shoptext h3 {
  color: #252525;
  margin-top: 13px;
  font-weight: bold;
  font-size: 19px;
}
.rating {
  margin: 5px 0;
}
/* .rating i {

} */

.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}
.subscribe-head {
  text-align: center;
}

.form-section input[type="email"] {
  border: 0 none;
  border-radius: 25px;
  color: #9b9b9b;
  font-size: 16px;
  font-style: normal;
  height: 51px;
  letter-spacing: 0;
  text-align: center;
  width: 460px;
}

.form-section input[type="submit"] {
  background: #1cb803;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-left: -60px;
  padding: 0 58px;
  text-transform: uppercase;
  font-size: 12px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

/* .rounded {
  border-radius: 0.25rem !important;
} */

/* CONTACT */
.contactInfo {
  padding: 70px 0;
}
.contact-Box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-info {
  width: 80%;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0px 0px 27px 0px rgb(243, 243, 243);
}
.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #1cb803;
  border-radius: 100px;
  font-size: 20px;
  color: #1cb803;
}

.box-info h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
}

.single-product {
  margin-top: 50px;
  margin-bottom: 50px;
}

.single-image {
  background-color: #f3fbf7;
  margin-right: 20px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-image img {
  width: 90%;
  height: 700px;
  object-fit: contain;
}
.product-name {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 30px;
}
.product-count .flex-box {
  padding: 15px 25px;
  border-bottom: 2px solid #f3f3f3;
}

.product-count .flex-box h6 {
  font-size: 16px;
}
.product-count .flex-box span {
  font-weight: 600;
  color: #000000;
}

.product-count .flex-box select {
  background-color: #f3f3f3;
  width: 100px;
  height: 40px;
  text-align: center;
  border: 0px;
  border-radius: 5px;
}

/* login */
.login-center {
  padding: 50px 0;
}

.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 35px 30px 30px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
}

.Login input {
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #8a8a8a;
}

.author-card-cover {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-image: url(https://gust.com/assets/blank_slate/Gust_Profile_CoverPhoto_Blank-21edf1e2890708d5a507204f49afc10b7dc58eb7baea100b68a1bc2c96948297.png);
}

.author-card-profile {
  padding: 20px 10px;
}
.author-card-avatar {
  padding: 0 20px;
}
.author-card-avatar img {
  width: 100%;
  height: 100px;
  object-fit: contain;
  /* border-radius: 100px; */
  margin-top: -60%;
  /* box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377); */
}

.total .sub {
  color: #8c8c8c;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 20px;
}

.total .total-price {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.cart-butoons {
  margin: 90px 0;
}

.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 12px 20px 1px rgb(64 64 64 / 9%);
  position: relative;
}

.cart-butoons a button,
.cart-butoons div button {
  width: 80%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
}

.cart-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

/* Payment */
.payment-container {
  margin-top: 20px;
}
.radio-container {
  padding: 10px 0;
  text-align: start;
}
.radio-container input {
  font-size: 17px;
}
.radio-container label {
  padding: 0 20px;
  font-size: 17px;
}
.order-detail p {
  margin-top: 2px;
}
.order-detail {
  padding: 30px 0;
  background-color: #e5fee9;
}

.order-detail h5 {
  margin-bottom: 6px;
}

.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}
/* 
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,600;1,200;1,500;1,600&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  font-family: "poppins", sans-serif;
}

.background {
  background-color: #ffffff;
  padding: 50px 0;
}

.container {
  max-width: 90%;
  margin: auto;
}

.d_flex {
  display: flex;
  justify-content: space-between;
}

.c_flex {
  display: flex;
  justify-content: space-between;
}

.f_flex {
  display: flex;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}
.uppercase {
  text-transform: capitalize;
}
.RText {
  text-align: right;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.name-button {
  padding: 5px 15px;
  border: 1px solid #e4e4e4;
  background-color: #fff;
  border-radius: 2px;
  margin: 0 15px;
}

.dropdown-menu {
  background-color: #000 !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.dropdown-menu a {
  padding: 10px 20px !important;
  color: #fff !important;
  margin: 0 !important;
}

.Login-Register a {
  text-transform: uppercase;
  font-size: 15px;
  margin-right: 2rem;
  position: relative;
}

.Login-Register a:last-child {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .Login-Register a,
  .search-button {
    font-size: 10px;
  }

  .order-detail {
    padding: 20px 0;
  }

  .order-box {
    width: 50px;
    height: 50px;
    font-size: 15px;
  }
}

.order-detail p {
  margin-top: 2px;
}
.badge {
  background-color: red;
  position: absolute;
  top: -20px;
  border-radius: 100px;
  padding: 5px 7px;
  font-size: 12px;
}

.subscribe-head h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 13px;
  text-transform: uppercase;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
i,
p,
span {
  padding: 0;
  margin: 0;
}

.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}

.subscribe-section {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.226)),
    url(https://kcm-www.s3.amazonaws.com/production/s3fs-public/2021-11/5%20Benefits%20of%20Thanksgiving%20and%20Praise.jpg?VersionId=jRIyMRaqp3a_Yp1OLsP1VezID2x.4cWm)
      no-repeat scroll 50% / cover;
  padding: 100px 0;
}

.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #1cb803;
  border-radius: 100px;
  font-size: 20px;
  color: #1cb803;
}

.box-info,
.contact-Box,
.info-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #e8f6ea;
  float: left;
  color: #088178;
  border: 1px solid #cce7d0;
  position: absolute;
  bottom: 10px;
  right: 40px;
}

.fa-solid,
.fas {
  font-weight: 900;
}

.fa-cart-shopping:before,
.fa-shopping-cart:before {
  /* content: "\f07a";  */
  width: 40px;
  height: 40px;
  /* border-radius: 50px; */
  /* background-color: #e8f6ea; */
  float: left;
  color: #088178;
  /* border: 1px solid #cce7d0; */
  position: relative;
  bottom: 10px;
  right: 40px;
  margin-left: 23%;
  margin-top: 23%;
  margin-bottom: 90%;
  position: static;
}

#product1 .pro .cart {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  background-color: #e8f6ea;
  font-weight: 500;
  color: #088178;
  border: 1px solid #cce7d0;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/* footer */

/* footer */

footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

footer .logo {
  margin-bottom: 30px;
}

footer h4 {
  font-size: 14px;
  padding-bottom: 20px;
}

footer p {
  font-size: 13px;
  margin: 0 0 8px 0;
}

footer a {
  font-size: 13px;
  text-decoration: none;
  color: #222;
  margin-bottom: 10px;
}

footer .follow {
  margin-top: 20px;
}

footer .follow i {
  color: #465b52;
  padding-right: 4px;
  cursor: pointer;
}

footer .install .row img {
  border: 1px solid #088178;
  border-radius: 6px;
}

footer .install img {
  margin: 10px 0 15px 0;
}

footer .follow i:hover,
footer a:hover {
  color: #088178;
}

footer .copyright {
  width: 100%;
  text-align: center;
}

.fmage {
  width: 50%;
  height: 50%;
}

/* footer */

.round-black-btn {
  border-radius: 4px;
  background: #000;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  transition: all 0.5s ease-in-out 0s;
}

.rating i {
  font-size: 12px;
  color: #ebb450;
}

.wizard .nav .active {
  background-color: #e5fee9;
  color: #000;
}

.wizard .nav button {
  padding: 15px 20px;
  font-size: 13px;
  background-color: #fff;
  color: #000;
  width: 100%;
  text-align: start;
  border-radius: 0;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
}

.badge2 {
  width: 20px;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #fff;
}

.form input {
  padding: 20px 10px;
  font-size: 17px;
  background-color: #e5fee9;
}

.form {
  padding: 20px 0;
}

.form-container button {
  padding: 15px 0;
  border: 0;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #fff;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.form label {
  padding: 10px 0;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 13px;
}

.col-4,
.col-6,
.row {
  margin: 0;
  padding: 0;
}

.table-responsive {
  width: 100%;
}

.Login2 button,
.Login2 button a,
.Login button,
.Login button a {
  text-transform: uppercase;
  color: #fff;
}

.Login2 button,
.Login button {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border: 0;
  background-color: #1cb803;
  border-radius: 5px;
}

.Login p {
  margin-top: 30px;
}

.Login p a {
  color: #7a7a7a;
}

a {
  text-decoration: none;
}

.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 35px 30px 30px;
  box-shadow: 0 1px 11px hsl(0deg 0% 66% / 27%);
  text-align: center;
}

a,
a:hover {
  color: #000;
}

.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 12px 20px 1px rgb(64 64 64 / 9%);
  position: relative;
}

.remove-button {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.fa-times:before {
  content: "\f00d";
}

.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 12px 20px 1px rgb(64 64 64 / 9%);
  position: relative;
}

.cart-price h6,
.cart-qty h6 {
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  text-transform: uppercase;
}

.cart-qty select {
  width: 100%;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}

.cart-price h4,
.cart-text a h4 {
  font-size: 17px;
  font-weight: 700;
}

.total {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: end;
  padding: 0 30px;
}

.cart-buttons a:first-child button {
  background-color: #000;
}

.cart-buttons a button,
.cart-buttons div button {
  width: 80%;
  padding: 15px 0;
  border: 0;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #fff;
}

.order-box {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.order-detail {
  padding: 30px 0;
  background-color: #e5fee9;
}

.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}

.order-product h4 {
  font-size: 13px;
  margin-bottom: 10px;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.subtotal-order button {
  width: 100%;
  padding: 15px 0;
  border: 0;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #fff;
}
.database{
  text-align: center;
  font-weight: 900;
  font-size: larger;
  font-size: 40px;
  margin: 60px;
  /* color: blue; */
}